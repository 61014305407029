import wellKnown from 'fonts/well-known'
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil'

const fontsState = atom<{ [key: string]: boolean }>({
  key: 'fontsState',
  default: {},
})

const platformFontsSelector = selector<string[]>({
  key: 'platformFontsSelector',
  get: async () => {
    await (document as any).fonts.ready
    let testFontList: string[] = wellKnown
    const platform = navigator.platform.toLowerCase()
    if (platform.startsWith('win')) {
      testFontList = [...wellKnown, ...(await import('fonts/windows')).default]
    } else if (platform.startsWith('mac')) {
      testFontList = [...wellKnown, ...(await import('fonts/macos')).default]
    }
    return testFontList.filter(font => (document as any).fonts.check(`12px ${font}`))
  },
})

const googleFontsSelector = selector({
  key: 'googleFontsSelector',
  get: async () => (await import('fonts/google')).default,
})

export const selectableFontsSelector = selector({
  key: 'selectableFontsSelector',
  get: ({ get }) => {
    return [...new Set([...get(googleFontsSelector).map<string>(font => (font as any).name), ...get(platformFontsSelector)])].sort()
  },
})

export const availableFontsSelector = selector({
  key: 'availableFontsSelector',
  get: ({ get }) => {
    const fonts = get(fontsState)
    return Object.keys(fonts).filter(font => fonts[font])
  },
})

export const useRequestFont = () => {
  const [fonts, setFonts] = useRecoilState(fontsState)
  const googleFonts = useRecoilValue(googleFontsSelector)
  return async (font: string) => {
    if (font in fonts) { return }
    setFonts({ ...fonts, [font]: false })
    const googleFont = googleFonts.find(family => family.name === font)
    if (googleFont) {
      const font = new (window as any).FontFace(googleFont.name, `url(${googleFont.url})`)
      await font.load();
      (document as any).fonts.add(font)
      setFonts(fonts => ({ ...fonts, [font]: true }))
    } else {
      await (document as any).fonts.load(`12px ${font}`)
    }
  }
}
