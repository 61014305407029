import { useEffect } from 'react'
import { useState } from 'react'
import { useRef } from 'react'
import Canvas from 'app/canvas'
import { useRecoilValue } from 'recoil'
import { selectedCanvasState } from 'state/canvas'
import { } from 'styled-components/macro'


export default () => {
  const canvasElement = useRef<HTMLCanvasElement>(null)
  const viewportElement = useRef<HTMLElement>(null)

  const selectedCanvas = useRecoilValue(selectedCanvasState)
  const [context, setContext] = useState<CanvasRenderingContext2D>()
  const [viewportSize, setViewportSize] = useState<NumTuple>([0, 0])

  /**
   * resize canvas
   */
  useEffect(() => {
    if (!viewportElement.current) { return }
    const resize = () => {
      if (!viewportElement.current) { return }
      setViewportSize([viewportElement.current.offsetWidth, viewportElement.current.offsetHeight])
    }
    resize()
    addEventListener('resize', resize)
    return () => {
      removeEventListener('resize', resize)
    }
  }, [viewportElement, setViewportSize])

  /**
   * store canvas context in state
   */
  useEffect(() => {
    setContext(canvasElement.current?.getContext('2d') || undefined)
  }, [canvasElement, setContext])




  return (
    <section ref={viewportElement} css={{
      flex: 1,
      zIndex: 0,
      overflow: 'hidden',
      position: 'relative',
    }}>
      <canvas css="position:absolute;overflow:hidden;" ref={canvasElement} width={viewportSize[0]} height={viewportSize[1]} />
      {context && selectedCanvas && <Canvas canvasId={selectedCanvas} ctx={context} viewport={viewportSize} />}
    </section>
  )
}
