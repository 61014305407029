
import { atom } from 'recoil'

export enum MouseMode {
  Default,
  DragCanvas,
  InsertText,
  PlaceImage,
  FreehandDrawing,
  DrawCircle,
  DrawRectangle,
}

export const mouseModeState = atom<MouseMode>({
  key: 'mouseModeState',
  default: MouseMode.Default,
})
