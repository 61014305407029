import Header from 'app/header'
import Canvas from 'app/viewport'
import Sidebar from 'app/sidebar'
import Toolbar from 'app/toolbar'
import Controls from 'app/controls'

import { } from 'styled-components/macro'

import { useCreateCanvas } from 'state/canvas'
import { useEffect } from 'react'
import { useRef } from 'react'

import AppContext from 'app-context'
import { useRecoilValue } from 'recoil'
import { selectableFontsSelector } from 'state/fonts'


export default () => {
  const createCanvas = useCreateCanvas()
  useRecoilValue(selectableFontsSelector)
  const fileOpenElement = useRef<HTMLInputElement>(null)

  useEffect(() => {
    createCanvas({ name: 'untitled', dimensions: [500, 500], background: '#ffffff' })
  }, [])

  return (
    <>
      <AppContext.Provider value={{ fileOpenElement }}>
        <Header />
        <main css={`
          flex: 1;
          display: flex;
          position: relative;
          z-index: 0;
        `}>
          <Controls />
          <Toolbar />
          <Canvas />
          <Sidebar />
        </main>
      </AppContext.Provider>
      <form><input ref={fileOpenElement} type="file" accept="image/*" hidden /></form>
    </>
  )
}
