import Layers from 'app/layers'
import LayerAttributes from 'app/layer-attributes'
import Icon from 'icon'
import { useRecoilValue } from 'recoil'
import { selectedCanvasState } from 'state/canvas'

import { } from 'styled-components/macro'


export default () => {
  const selectedCanvas = useRecoilValue(selectedCanvasState)

  if (!selectedCanvas) { return null }

  return (
    <aside css={`
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      width: 250px;
      border-radius: 5px;
      background: #131313;
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
      overflow: hidden;
    `}>
      <Section icon="sliders" label="Attributes">
        <LayerAttributes canvasId={selectedCanvas} />
      </Section>
      <Section icon="layers" label="Layers">
        <Layers canvasId={selectedCanvas} />
      </Section>
    </aside>
  )
}



const Section = ({ children, icon, label }: { children: any, icon: string, label: string }) => {
  return (
    <section css="flex:1;">
      <div css={{
        background: '#000',
        lineHeight: '2em',
        padding: '0 10px',
      }}>
        <Icon css="margin-right: 10px;" name={icon} size={16} />
        {label}
      </div>
      {children}
    </section>
  )
}
