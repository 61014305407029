import { v4 as uuidv4 } from 'uuid'

import {
  DefaultValue, useRecoilCallback,
  atom, atomFamily,
  selector, selectorFamily,
} from 'recoil'

const canvasesState = atomFamily<Omit<Canvas, 'id'> | null, string>({
  key: 'canvasesState',
  default: null,
})

const canvasIdState = atom<string[]>({
  key: 'canvasIdState',
  default: [],
})

const canvasSelector = selectorFamily<Canvas | null, string | null>({
  key: 'canvasSelector',
  get: (id) => ({ get }) => {
    if (id === null) { return null }
    const entity = get(canvasesState(id))
    return entity ? { id, ...entity } : null
  },
  set: (id) => ({ get, set, reset }, entity) => {
    if (id === null) { return }
    if (entity instanceof DefaultValue) {
      reset(canvasesState(id))
      set(canvasIdState, get(canvasIdState).filter(entityId => entityId !== id))
    } else {
      set(canvasesState(id), entity)
      if (!get(canvasIdState).includes(id)) { set(canvasIdState, prev => [...prev, id]) }
    }
  },
})

////////////////////////

export const selectedCanvasState = atom<string | null>({
  key: 'selectedCanvasState',
  default: null,
})

export const selectedCanvasSelector = selector<Canvas | null>({
  key: 'selectedCanvasSelector',
  get: ({ get }) => get(canvasSelector(get(selectedCanvasState))),
  set: ({ get, set }, entity) => set(canvasSelector(get(selectedCanvasState)), entity),
})

export const allCanvasSelector = selector<Canvas[]>({
  key: 'allCanvasSelector',
  get: ({ get }) => get(canvasIdState).map(id => {
    const entity = get(canvasesState(id))
    return entity ? { id, ...entity } : null
  }).filter((entity): entity is Canvas => entity !== null),
})


export const useCreateCanvas = () => {
  const addEntity = useRecoilCallback(({ set }) => async (entity: Canvas) => {
    set(canvasSelector(entity.id), entity)
    set(selectedCanvasState, entity.id)
  })

  return (entity: DistributiveOmit<Canvas, 'id'>) => {
    const id = uuidv4()
    addEntity({ id, ...entity })
    return id
  }
}
