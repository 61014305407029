import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { allLayerSelector, selectedLayerState } from 'state/layer'
import { } from 'styled-components/macro'

interface LayersProps {
  canvasId: string
}


export default ({ canvasId }: LayersProps) => {
  const layers = useRecoilValue(allLayerSelector(canvasId))
  const setSelectedLayer = useSetRecoilState(selectedLayerState(canvasId))
  const selectedLayer = useRecoilValue(selectedLayerState(canvasId))
  return (
    <>
      {layers.map((layer, index) => <Layer key={index} data={layer} selected={selectedLayer === layer.id} onClick={() => setSelectedLayer(layer.id)} />)}
    </>
  )
}


const Layer = ({ data, onClick, selected }: { data: Layer, onClick: () => void, selected: boolean }) => {
  const [thumbnail, setThumbnail] = useState<string>()

  useEffect(() => {
    if (data.kind === 'image') {
      const [width, height] = [50, 50]
      const canvas = new OffscreenCanvas(width, height),
        ctx = canvas.getContext('2d')
      if (!ctx) { return }
      (async () => {
        ctx.drawImage(data.image, 0, 0, width, height)
        setThumbnail(URL.createObjectURL(await canvas.convertToBlob()))
      })()

    }
  }, [data.id, setThumbnail])

  return (
    <div onClick={onClick}
      css={{
        lineHeight: 3,
        borderBottom: '1px solid #464646',
        paddingRight: 5,
        background: selected ? '#212121' : 'none',
        display: 'flex',
      }}
    >
      <div css={{
        display: 'inline-block',
        width: 50,
        minWidth: 50,
        backgroundColor: '#000',
        background: thumbnail ? `url(${thumbnail})` : '#000',
        marginRight: 5,
      }} />
      <div css={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>
        {data.kind === 'image' ? data.label : data.text}
      </div>
    </div>
  )
}
