import { } from 'styled-components/macro'
import Icon from 'icon'
import { useRecoilState } from 'recoil'
import { MouseMode, mouseModeState } from 'state'


type ControlMode = {
  id: string
  label: string
  mouseMode: MouseMode
  icon: {
    name: string
    filled?: boolean
  }
}

const actions: ControlMode[] = [
  { id: 'default', label: 'Select', mouseMode: MouseMode.Default, icon: { name: 'mouse-pointer', filled: true } },
  { id: 'move', label: 'Move Canvas', mouseMode: MouseMode.DragCanvas, icon: { name: 'move' } },
  { id: 'text', label: 'Insert Text', mouseMode: MouseMode.InsertText, icon: { name: 'type' } },
  { id: 'image', label: 'Place Image', mouseMode: MouseMode.PlaceImage, icon: { name: 'image' } },
  //{ id: 'draw', label: 'Draw Freehand', mouseMode: MouseMode.FreehandDrawing, icon: { name: 'edit-2' } },
  //{ id: 'circle', label: 'Draw Circle', mouseMode: MouseMode.DrawCircle, icon: { name: 'circle' } },
  //{ id: 'rectangle', label: 'Draw Rectangle', mouseMode: MouseMode.DrawRectangle, icon: { name: 'square' } },
]



export default () => {
  const [mouseMode, setMouseMode] = useRecoilState(mouseModeState)

  return (
    <div css={{
      top: 10,
      left: 10,
      borderRadius: 5,
      overflow: 'hidden',
      width: 50,
      textAlign: 'center',
      background: '#131313',
      boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.25)',
      position: 'absolute',
      zIndex: 5,
    }}>
      {actions.map(action => <div css={`
        height:50px;
        line-height: 50px;
        cursor: pointer;
        position:relative;

        &[data-active="true"]{
          background:rgb(246, 79, 89);
          color:#131313;
        }
      `}
        data-active={mouseMode === action.mouseMode} onClick={() => setMouseMode(action.mouseMode)} title={action.label} key={action.id}><Icon css={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          width: '100%',
          left: 0,
        }} size={18} {...action.icon} /></div>)}
    </div>
  )
}
