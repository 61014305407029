export default [
  'Arial',
  'Arial Black',
  'Ubuntu',
  'Comic Sans MS',
  'Courier',
  'Courier New',
  'Helvetica',
  'Times',
  'Times New Roman',
  'Impact',
  'Verdana',
]
