import { filter } from 'app/layer-attributes'

export const distance = (p1: NumTuple, p2: NumTuple) => {
  return Math.sqrt((p2[0] - p1[0]) * (p2[0] - p1[0]) + (p2[1] - p1[1]) * (p2[1] - p1[1]))
}

export const dotsFromLayerDetails = (details: LayerDetails): [NumTuple, NumTuple, NumTuple, NumTuple] => {
  return [
    [details.left, details.top],
    [details.left + details.width, details.top],
    [details.left, details.top + details.height],
    [details.left + details.width, details.top + details.height],
  ]
}

const applyLayerAttributesToContext = (layer: Layer, ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D) => {
  if (layer.kind === 'text') {
    ctx.fillStyle = layer.color
    ctx.font = `${layer.fontSize}px ${layer.fontFamily}`
    ctx.textBaseline = 'hanging'
  }
}

export const layerDetails = (layer: Layer): LayerDetails => {
  let left = 0, top = 0, width = 0, height = 0

  if (layer.kind === 'image') {
    ([width, height] = layer.dimensions);
    ([left, top] = layer.offset)
  } else if (layer.kind === 'text') {
    const ctx = new OffscreenCanvas(0, 0).getContext('2d')
    if (!ctx) { throw 'unable to create offscreencanvas' }

    applyLayerAttributesToContext(layer, ctx)

    const measurement = ctx.measureText(layer.text)
    left = layer.offset[0] - measurement.actualBoundingBoxLeft
    top = layer.offset[1] - measurement.actualBoundingBoxAscent
    height = measurement.actualBoundingBoxDescent - measurement.actualBoundingBoxAscent
    width = measurement.actualBoundingBoxRight - measurement.actualBoundingBoxLeft
  }

  return { id: layer.id, left, top, width, height }
}

export const applyLayerToContext = (layer: Layer, ctx: CanvasRenderingContext2D | OffscreenCanvasRenderingContext2D): LayerDetails => {
  if (layer.filter.length) {
    ctx.filter = layer.filter.map(f => filter.find(f2 => f2.id === f.kind)?.output?.replace('{0}', f.value.toString())).join(' ')
  }

  if (layer.kind === 'text') {
    applyLayerAttributesToContext(layer, ctx)
    ctx.fillText(layer.text, ...layer.offset)
  } else if (layer.kind === 'image') {
    ctx.drawImage(layer.image, ...layer.offset, ...layer.dimensions)
  }

  return layerDetails(layer)
}

export const coordinatesInBoundingBox = (x: number, y: number, box: LayerDetails): boolean => {
  return x >= box.left && y > box.top && x <= box.left + box.width && y <= box.top + box.height
}
