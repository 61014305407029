import svg from 'feather-icons/dist/feather-sprite.svg'

import { } from 'styled-components/macro'

interface IconProps {
  name: string
  size?: number
  filled?: boolean
}

export default ({ name, size = 24, filled = false, ...props }: IconProps) => {
  return (
    <svg
      {...props}
      css="vertical-align:middle;position:relative;top:-1px;"
      width={size}
      height={size}
      fill={filled ? 'currentColor' : 'none'}
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <use xlinkHref={`${svg}#${name}`} />
    </svg>
  )
}
