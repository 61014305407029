import { v4 as uuidv4 } from 'uuid'

import {
  DefaultValue,
  atomFamily,
  selectorFamily, useRecoilCallback,
} from 'recoil'

const layersState = atomFamily<DistributiveOmit<Layer, 'id'> | null, LayerId>({
  key: 'layersState',
  default: null,
})

const layerIdsState = atomFamily<string[], string>({
  key: 'layerIdsState',
  default: [],
})

const layerSelector = selectorFamily<Layer | null, LayerId | null>({
  key: 'layerSelector',
  get: (id) => ({ get }) => {
    if (id === null) { return null }
    const entity = get(layersState(id))
    return entity ? { id, ...entity } : null
  },
  set: (id) => ({ get, set, reset }, layer) => {
    if (id === null) { return }
    if (layer instanceof DefaultValue) {
      reset(layersState(id))
      set(layerIdsState(id.canvas), get(layerIdsState(id.canvas)).filter(layerId => layerId !== id.self))
    } else {
      set(layersState(id), layer)
      if (!get(layerIdsState(id.canvas)).includes(id.self)) { set(layerIdsState(id.canvas), prev => [...prev, id.self]) }
    }
  },
})

////////////////////////

export const selectedLayerState = atomFamily<LayerId | null, string>({
  key: 'selectedLayerState',
  default: null,
})

export const selectedLayerSelector = selectorFamily<Layer | null, string>({
  key: 'selectedLayerSelector',
  get: (canvas) => ({ get }) => get(layerSelector(get(selectedLayerState(canvas)))),
  set: (canvas) => ({ get, set }, entity) => set(layerSelector(get(selectedLayerState(canvas))), entity),
})

export const allLayerSelector = selectorFamily<Layer[], string>({
  key: 'allLayerSelector',
  get: (canvas) => ({ get }) => get(layerIdsState(canvas)).map(id => {
    const entity = get(layersState({ self: id, canvas }))
    return entity ? { id: { self: id, canvas }, ...entity } : null
  }).filter((entity): entity is Layer => entity !== null),
})

export const useCreateLayer = () => {
  const addEntity = useRecoilCallback(({ set }) => async (entity: Layer) => {
    set(layerSelector(entity.id), entity)
    set(selectedLayerState(entity.id.canvas), entity.id)
  })

  return (canvas: string, entity: DistributiveOmit<Layer, 'id'>) => {
    const id = uuidv4()
    addEntity({ id: { self: id, canvas }, ...entity })
    return id
  }
}
