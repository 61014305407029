import { } from 'styled-components/macro'

import { useRecoilState, useRecoilValue } from 'recoil'
import { allCanvasSelector, selectedCanvasState, useCreateCanvas } from 'state/canvas'

import Icon from 'icon'
import { useState } from 'react'

import logoSvg from 'assets/logo.svg'
const Logo = () => {
  return <div css={`
  margin-left:10px;
  display:inline-block;
  height: 40px;
  width: 175px;
  background: url(${logoSvg}) no-repeat center center;
  background-size: contain;
  margin-right:10px;
  vertical-align: top;
`} />
}

import githubCornerRightSvg from 'assets/github-corner-right.svg'
const GitHubLink = () => {
  return <a href="https://github.com/reggiori/canvas" title="GitHub" target="_blank" rel="noreferrer" css={{
    position: 'absolute',
    top: 0,
    right: 0,
    height: 60,
    width: 60,
    background: `url(${githubCornerRightSvg})`,
  }} />
}

export default () => {
  return (
    <header css={`
    background: #000;
    height: 40px;
    line-height: 40px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
    z-index: 10;
  `}>
      <Logo />
      <GitHubLink />
      <CanvasSelector />
    </header>
  )
}






const CanvasSelector = () => {
  const [open, setOpen] = useState(false)
  const canvases = useRecoilValue(allCanvasSelector)
  const [selectedCanvas, setSelectedCanvas] = useRecoilState(selectedCanvasState)
  const createCanvas = useCreateCanvas()

  return (
    <div css={`
      line-height:40px;
      background:none;
      border:none;
      color:#fff;
      display: inline-block;
      cursor:pointer;
      position:absolute;
      left:calc(50% - 100px);
      transform:translateX(-50%);
      top:0;
    `}
      tabIndex={0} onBlur={() => setOpen(false)}>
      <div css="height:40px;"
        onClick={() => setOpen(open => !open)}>
        {canvases.find(canvas => canvas.id === selectedCanvas)?.name}
        <Icon css={{
          marginLeft: 3,
        }} name="chevron-down" size={16} />
      </div>

      <div css={`
        position:absolute;
        margin-top:40px;
        left:50%;
        transform:translateX(-50%);
        top:-10px;
        opacity: 0;
        pointer-events: none;
        transition: 200ms ease-in-out;
        transition-property: top opacity;

        &[data-open="true"]{
          pointer-events: all;
          top:0;opacity: 1;
        }
        `}
        data-open={open}>

        <div css={`
          position: absolute;
          top: 0px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid black;
        `} />
        <div css={`
          line-height:2em;
          min-width:150px;
          text-align:left;
          margin-top:5px;
          background:#000;
          border-radius: 3px;
          padding:5px 10px;
          overflow: hidden;
        `}>
          {canvases.map(canvas => {
            return (
              <div css={`
                border-bottom:1px solid #252525;
                &:last-child{
                  border-bottom: none;
                }
              `}
                key={canvas.id} onClick={() => { setSelectedCanvas(canvas.id); setOpen(false) }} >
                {canvas.name}
                {canvas.id === selectedCanvas && <Icon css="margin-left:5px;" name="check" size={14} />}
              </div>
            )
          })}
          <div css={`
            background:#f64f59;
            color:#131313;
            margin:0 -10px -5px;
            text-align: center;
            text-transform: uppercase;
          `}
            onClick={() => { createCanvas({ name: 'untitled', dimensions: [500, 500], background: '#ffffff' }); setOpen(false) }}>New Canvas</div>
        </div>
      </div>
    </div>
  )
}
