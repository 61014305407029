import Icon from 'icon'
import { useEffect, useRef } from 'react'
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil'
import { selectedCanvasSelector, selectedCanvasState } from 'state/canvas'
import { selectableFontsSelector } from 'state/fonts'
import { selectedLayerSelector } from 'state/layer'
import { } from 'styled-components/macro'


export default () => {
  const selectedCanvas = useRecoilValue(selectedCanvasState)
  if (!selectedCanvas) { return null }
  return <Toolbar canvasId={selectedCanvas} />
}

interface ToolbarProps {
  canvasId: string
}


const Toolbar = ({ canvasId }: ToolbarProps) => {
  const [selectedLayer, setSelectedLayer] = useRecoilState(selectedLayerSelector(canvasId))

  return (
    <div css={`
    background: #131313;
     box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
     position:absolute;
     border-radius: 5px;
     top:10px;
     left:calc(50% - 100px);
     transform:translateX(-50%);
     line-height:50px;
     height:50px;
     padding: 0 20px;
     z-index:10;
     display: inline-flex;
     flex-wrap: wrap;
     align-items : center;
     gap: 24px;
 `}>
      {selectedLayer === null && <Default />}
      {selectedLayer?.kind === 'text' && <TextToolbar layer={selectedLayer} setLayer={setSelectedLayer} />}
      {selectedLayer?.kind === 'image' && <ImageToolbar layer={selectedLayer} setLayer={setSelectedLayer} />}
    </div>
  )
}


const Default = () => {
  const [selectedCanvas, setSelectedCanvas] = useRecoilState(selectedCanvasSelector)
  if (!selectedCanvas) { return null }

  return (
    <>
      <ConfigElement icon="tag">
        <input style={{ width: 100 }} value={selectedCanvas.name} onChange={ev => setSelectedCanvas({ ...selectedCanvas, name: ev.target.value })} />
      </ConfigElement>
      <ConfigElement icon="maximize-2">
        <input type="number" style={{ width: 50 }} value={selectedCanvas.dimensions[0]} onChange={ev => setSelectedCanvas({ ...selectedCanvas, dimensions: [parseInt(ev.target.value), selectedCanvas.dimensions[1]] })} />
        <input type="number" style={{ width: 50 }} value={selectedCanvas.dimensions[1]} onChange={ev => setSelectedCanvas({ ...selectedCanvas, dimensions: [selectedCanvas.dimensions[0], parseInt(ev.target.value)] })} />
      </ConfigElement>
      <ConfigElement icon="hash">
        <input type="color" style={{ width: 50 }} value={selectedCanvas.background} onChange={ev => setSelectedCanvas({ ...selectedCanvas, background: ev.target.value })} />
      </ConfigElement>
    </>
  )
}



const TextToolbar = ({ layer, setLayer }: { layer: Layer & { kind: 'text' }, setLayer: SetterOrUpdater<Layer | null> }) => {
  const input = useRef<HTMLInputElement>(null)
  const fonts = useRecoilValue(selectableFontsSelector)
  useEffect(() => {
    if (input.current) { input.current.focus() }
  }, [input])

  return (
    <>
      <ConfigElement icon="maximize-2">
        <input type="number" style={{ width: 50 }} value={layer.fontSize} onChange={ev => setLayer({ ...layer, fontSize: parseInt(ev.target.value) })} />
      </ConfigElement>
      <ConfigElement icon="type">
        <select value={layer.fontFamily} onChange={ev => setLayer({ ...layer, fontFamily: ev.target.value })}>
          {fonts.map(font => <option key={font} value={font}>{font}</option>)}
        </select>
      </ConfigElement>
      <ConfigElement icon="hash">
        <input type="color" style={{ width: 50 }} value={layer.color} onChange={ev => setLayer({ ...layer, color: ev.target.value })} />
      </ConfigElement>
      <div css={{
        position: 'absolute',
        width: 250,
        background: '#000',
        height: 30,
        top: 50,
        left: '50%',
        transform: 'translateX(-50%)',
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        lineHeight: '24px',
      }}>
        <input ref={input} css={{
          textAlign: 'left',
          padding: '0 10px',
          width: '100%',
          height: 24,
          border: 'none',
        }} value={layer.text} onChange={ev => setLayer({ ...layer, text: ev.target.value })} placeholder="Type something..." />
      </div>
    </>
  )
}


const ImageToolbar = ({ layer, setLayer }: { layer: Layer & { kind: 'image' }, setLayer: SetterOrUpdater<Layer | null> }) => {
  return (
    <>
      todo: image settings
    </>
  )
}


const ConfigElement = ({ children, icon }: { children: any, icon: string }) => {
  return (
    <div css={`
    line-height:28px;
    input,select{
      margin-left:10px;
    }
  `}>
      <Icon name={icon} size={16} />
      {children}
    </div>
  )
}
