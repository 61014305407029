import { Component, ErrorInfo, StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { RecoilRoot } from 'recoil'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import App from 'app'

import 'normalize.css'
import 'index.scss'
import styled, { createGlobalStyle } from 'styled-components'
import { } from 'styled-components/macro'

const GlobalStyle = createGlobalStyle`
  *{
    outline: none;
  }

  body {
    background: #252525;
    color: #fff;
    user-select: none;
    cursor: default;
    font-family: 'Lato', sans-serif;
    font-size:16px;
    overscroll-behavior: none ;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
`

interface ErrorBoundaryProps {
  children: any
}
interface ErrorBoundaryState {
  error?: Error
  screenSize: NumTuple
}

const ErrorContainer = styled.div`
  text-align: center;
  padding: 50px;
`

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: Readonly<ErrorBoundaryState> = {
    screenSize: [innerWidth, innerHeight],
  }

  componentDidMount() {
    addEventListener('resize', () => this.setState({ screenSize: [innerWidth, innerHeight] }))
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(errorInfo.componentStack)
    this.setState({ error })
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorContainer>
          <h1>Something went wrong.</h1>
        </ErrorContainer>
      )
    }

    if (this.state.screenSize[0] < 800 || this.state.screenSize[1] < 500) {
      return (
        <ErrorContainer>
          <h1>Browser window too small.</h1>
        </ErrorContainer>
      )
    }

    return this.props.children
  }
}


ReactDOM.render(
  <StrictMode>
    <GlobalStyle />
    <Suspense fallback={null}>
      <RecoilRoot>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </RecoilRoot>
    </Suspense>
  </StrictMode>,
  document.getElementById('root'),
)

serviceWorkerRegistration.register()
