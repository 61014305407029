import { useRecoilState } from 'recoil'
import { selectedLayerSelector } from 'state/layer'

interface LayerAttributesProps {
  canvasId: string
}

export default ({ canvasId }: LayerAttributesProps) => {
  const [layer, setLayer] = useRecoilState(selectedLayerSelector(canvasId))
  if (!layer) { return null }

  return (
    <section id="layer-attributes">
      <label>x-pos</label>
      <input type="number" value={layer.offset[0]} onChange={ev => setLayer({ ...layer, offset: [parseInt(ev.target.value), layer.offset[1]] })} />
      <label>y-pos</label>
      <input type="number" value={layer.offset[1]} onChange={ev => setLayer({ ...layer, offset: [layer.offset[0], parseInt(ev.target.value)] })} />

      {/*filter.map((attr, key) => <Attribute
        key={key} {...attr}
        initialValue={layer.filter.find(filter => filter.kind === attr.id)?.value}
      setVal={val => setLayer({ ...layer, filter: [...layer.filter.filter(filter => filter.kind !== attr.id), { kind: attr.id, value: val }] })} />)*/}
    </section>
  )
}

/*const Attribute = (attr: LayerAttribute & { initialValue: number | undefined, setVal: (n: number) => void }) => {
  if (attr.kind === 'checkbox') { return null }
  const { label, min, max, defaultVal, initialValue } = attr
  return (
    <div>
      <label>{label}</label><br />
      <input type="range" min={min} max={max} value={initialValue || defaultVal} onChange={ev => attr.setVal(parseInt(ev.target.value))} className="slider" id="myRange"></input>
    </div >
  )
}*/


export const filter: LayerAttribute[] = [
  { id: 'blur', label: 'Blur', kind: 'range', min: 0, max: 20, output: 'blur({0}px)', defaultVal: 0 },
  { id: 'brightness', label: 'Brightness', kind: 'range', min: 0, max: 100, output: 'brightness({0}%)', defaultVal: 100 },
  { id: 'contrast', label: 'Contrast', kind: 'range', min: 0, max: 100, output: 'contrast({0}%)', defaultVal: 100 },
  /*{ label: 'Drop Shadow', kind: 'range', min: 0, max: 100, output: 'contrast({0}%)' },*/
  { id: 'grayscale', label: 'Grayscale', kind: 'range', min: 0, max: 100, output: 'grayscale({0}%)', defaultVal: 0 },
  { id: 'hue-rotate', label: 'Hue-Rotate', kind: 'range', min: 0, max: 360, output: 'hue-rotate({0}deg)', defaultVal: 0 },
  { id: 'invert', label: 'Invert', kind: 'range', min: 0, max: 100, output: 'invert({0}%)', defaultVal: 0 },
  { id: 'opacity', label: 'Opacity', kind: 'range', min: 0, max: 100, output: 'opacity({0}%)', defaultVal: 100 },
  { id: 'saturate', label: 'Saturate', kind: 'range', min: 0, max: 100, output: 'saturate({0}%)', defaultVal: 100 },
  { id: 'sepia', label: 'Sepia', kind: 'range', min: 0, max: 100, output: 'sepia({0}%)', defaultVal: 0 },

]
