import React, { useEffect, useState, useRef, useContext } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { mouseModeState, MouseMode } from 'state'
import { allLayerSelector, selectedLayerState, selectedLayerSelector, useCreateLayer } from 'state/layer'
import { applyLayerToContext, coordinatesInBoundingBox, distance, dotsFromLayerDetails, layerDetails } from 'utils'

import { selectedCanvasSelector } from 'state/canvas'
import { } from 'styled-components/macro'

import AppContext from 'app-context'
import { availableFontsSelector, useRequestFont } from 'state/fonts'

interface CanvasProps {
  ctx: CanvasRenderingContext2D
  viewport: NumTuple
  canvasId: string
}

export default ({ ctx, viewport, canvasId }: CanvasProps) => {
  const virtualCanvas = useRef<HTMLDivElement>(null)

  const canvas = useRecoilValue(selectedCanvasSelector)

  const appContext = useContext(AppContext)

  const downloadLink = useRef<HTMLAnchorElement>(null)

  const size = canvas?.dimensions || [0, 0]
  const [offscreen] = useState(() => new OffscreenCanvas(0, 0).getContext('2d'))
  const [dragStart, setDragStart] = useState<{ mouse: NumTuple, mouseOriginal: NumTuple, anchor: NumTuple }>()
  const [transform, setTransform] = useState<{ scale: number, translate: NumTuple }>({ scale: 1, translate: [0, 0] })
  const [keyPressed, setKeyPressed] = useState<{ [key: string]: boolean }>({ LeftMouseButton: false, Shift: false, Control: false })
  const [mousePos, setMousePos] = useState<NumTuple>()
  const [viewportOffset, setViewportOffset] = useState<NumTuple>([0, 40])

  const layers = useRecoilValue(allLayerSelector(canvasId))
  const [selectedLayer, setSelectedLayer] = useRecoilState(selectedLayerState(canvasId))
  const updateSelectedLayer = useSetRecoilState(selectedLayerSelector(canvasId))
  const [mouseMode, setMouseMode] = useRecoilState(mouseModeState)

  const createLayer = useCreateLayer()

  const requestFont = useRequestFont()
  const availableFonts = useRecoilValue(availableFontsSelector)

  const [selectedLayerDetails, setSelectedLayerDetails] = useState<LayerDetails>()
  const [activeResizeHandle, setActiveResizeHandle] = useState<number>()


  const offset: NumTuple = [(viewport[0] - size[0] - 200) / 2, (viewport[1] - size[1]) / 2]
  const canvasOffset: NumTuple = [
    (offset[0] + viewportOffset[0] - transform.translate[0] + (1 - transform.scale) * (size[0] / 2)),
    (offset[1] + viewportOffset[1] - transform.translate[1] + (1 - transform.scale) * (size[1] / 2)),
  ]


  useEffect(() => {
    if (dragStart) { return }
    if (selectedLayerDetails === undefined) {
      if (activeResizeHandle) { setActiveResizeHandle(undefined) }
      return
    }

    if (!mousePos) { return }

    let hover: number | undefined = undefined

    const dots = dotsFromLayerDetails(selectedLayerDetails)

    for (const dotIndex in dots) {
      if (distance(mousePos, dots[dotIndex]) < 15) {
        hover = parseInt(dotIndex)
        break
      }
    }

    if (hover !== activeResizeHandle) { setActiveResizeHandle(hover) }
  }, [selectedLayerDetails, mousePos, activeResizeHandle, setActiveResizeHandle, dragStart])

  useEffect(() => {
    if (dragStart) { return }
    if (!selectedLayer) {
      if (selectedLayerDetails) { setSelectedLayerDetails(undefined) }
      return
    }
    const layer = layers.find(layer => layer.id.canvas === selectedLayer.canvas && layer.id.self === selectedLayer.self)
    if (!layer || layer.kind !== 'image') { return }
    const details = layerDetails(layer)

    let foundDifference = false

    if (!selectedLayerDetails) {
      foundDifference = true
    } else {
      for (const key in details) {
        if ((details as any)[key] !== (selectedLayerDetails as any)[key]) {
          foundDifference = true
          break
        }
      }
    }



    if (foundDifference) { setSelectedLayerDetails(details) }
  }, [layers, selectedLayer, selectedLayerDetails, selectedLayerDetails, setSelectedLayer, dragStart])


  /**
   * store viewport-offset
   */
  useEffect(() => {
    if (virtualCanvas.current) {
      const rect = virtualCanvas.current.getBoundingClientRect()
      setViewportOffset([rect.left, rect.top])
    }
  }, [virtualCanvas])


  /**
   * invalidate mouse position
   */
  useEffect(() => {
    setMousePos(undefined)
  }, [transform, size, viewport])



  /**
   * disable default scroll-wheel behaviour
   */
  useEffect(() => {
    const fn = (event: WheelEvent) => event.preventDefault()
    virtualCanvas.current?.addEventListener('wheel', fn, { passive: false })
    return () => {
      virtualCanvas.current?.removeEventListener('wheel', fn)
    }
  }, [virtualCanvas])

  /**
   * keep track on which keyboard-keys are pressed
   */
  useEffect(() => {
    const cb = (nextState: boolean) => (event: KeyboardEvent) => {
      if (event.key in keyPressed && keyPressed[event.key] !== nextState) { setKeyPressed({ ...keyPressed, [event.key]: nextState }) }
      if (nextState && keyPressed.Control && event.key === 's') {
        // todo: open export dialog
        event.preventDefault();

        (async () => {
          if (!downloadLink.current) { return }
          const ofc = new OffscreenCanvas(...size)
          const ofcContext = ofc.getContext('2d')
          if (ofcContext) {
            for (const layer of layers) {
              ofcContext.save()
              applyLayerToContext(layer, ofcContext)
              ofcContext.restore()
            }
            const blob = await ofc.convertToBlob({ type: 'image/png' })
            const url = URL.createObjectURL(blob)
            downloadLink.current.href = url
            downloadLink.current.download = `${canvas?.name || 'untitled'}.png`

            downloadLink.current.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window,
              }),
            )

            setTimeout(() => {
              URL.revokeObjectURL(url)
            }, 5000)
          }
        })()

      }
    }

    const keyDown = cb(true)
    const keyUp = cb(false)

    addEventListener('keydown', keyDown)
    addEventListener('keyup', keyUp)

    return () => {
      removeEventListener('keydown', keyDown)
      removeEventListener('keyup', keyUp)
    }
  }, [keyPressed, setKeyPressed, downloadLink])

  /**
   * Draw on transformed canvas
   *
   * @param cb
   */
  const draw = (cb: (ctx: CanvasRenderingContext2D) => void) => {
    ctx.save()
    ctx.translate(offset[0] + size[0] / 2, offset[1] + size[1] / 2)
    ctx.translate(-transform.translate[0], -transform.translate[1])
    ctx.scale(transform.scale, transform.scale)
    ctx.translate(-size[0] / 2, -size[1] / 2)

    cb(ctx)
    ctx.restore()
  }



  /**
   * Clear viewport and re-draw blank canvas
   */
  const clear = () => {
    ctx.clearRect(0, 0, ...viewport)
    draw(ctx => {
      ctx.beginPath()
      ctx.rect(0, 0, ...size)
      ctx.fillStyle = canvas?.background || '#fff'
      ctx.shadowColor = '#000'
      ctx.shadowBlur = 50
      ctx.fill()
    })
  }

  /**
   * re-render entire viewport
   */
  const render = () => {
    clear()
    // draw each layer
    for (const layer of layers) {
      draw(ctx => {
        applyLayerToContext(layer, ctx)
      })
    }

    // outline selected layer
    if (selectedLayer) {
      const selected = layers.find(layer => layer.id === selectedLayer)
      if (selected && offscreen) {
        const details = applyLayerToContext(selected, offscreen)
        draw(ctx => {
          ctx.lineWidth = 3 / transform.scale
          ctx.strokeStyle = '#f64f59'
          ctx.beginPath()
          ctx.rect(details.left, details.top, details.width, details.height)
          ctx.stroke()

          if (selected.kind !== 'image') { return }
          // draw dot on every vertex
          const dots = dotsFromLayerDetails(details)
          for (const dotIndex in dots) {
            ctx.beginPath()
            ctx.arc(...dots[dotIndex], (parseInt(dotIndex) === activeResizeHandle ? 2 : 1) * 5 / transform.scale, 0, 2 * Math.PI)
            ctx.fillStyle = '#f64f59'
            ctx.fill()
          }
        })
      }
    }
  }

  /**
   * Apply canvas transformation to mouse coordinates
   *
   * @param coord
   * @returns
   */
  const adjustMouse = (...coord: NumTuple): NumTuple => [
    (coord[0] - canvasOffset[0]) / transform.scale,
    (coord[1] - canvasOffset[1]) / transform.scale,
  ]

  //useEffect(() => {
  //  // create layers for testing
  //  createLayer(canvasId, { kind: 'text', color: '#ff0000', fontFamily: 'Arial', fontSize: 20, filter: [], offset: [100, 100], text: 'Test layer 1', visibility: true })
  //  createLayer(canvasId, { kind: 'text', color: '#ff0000', fontFamily: 'Arial', fontSize: 20, filter: [], offset: [100, 300], text: 'Test layer 2', visibility: true })
  //  createLayer(canvasId, { kind: 'text', color: '#ff0000', fontFamily: 'Arial', fontSize: 20, filter: [], offset: [300, 100], text: 'Test layer 3', visibility: true })
  //}, [canvasId])



  useEffect(() => {
    if (!appContext.fileOpenElement.current) { return }
    const listener = () => {
      for (const item of appContext.fileOpenElement.current?.files || []) {
        const reader = new FileReader()
        const image = new Image()
        reader.addEventListener('load', () => {
          image.src = reader.result as string
        })
        reader.readAsDataURL(item)
        image.addEventListener('load', () => {
          createLayer(canvasId, {
            kind: 'image',
            dimensions: [image.width, image.height],
            image: image,
            filter: [],
            offset: mousePos || [0, 0],
            visibility: true,
            label: item.name,
          })
        })
      }
    }

    appContext.fileOpenElement.current.addEventListener('change', listener)
    return () => {
      appContext.fileOpenElement.current?.removeEventListener('change', listener)
    }

  }, [appContext.fileOpenElement])


  /**
   * mousedown event handler
   * @param event
   * @returns
   */
  const mouseDown = (event: React.MouseEvent | React.TouchEvent) => {
    const { clientX, clientY } = 'touches' in event ? event.touches[0] : event
    setKeyPressed(cur => ({ ...cur, LeftMouseButton: true }))


    const [x, y] = adjustMouse(clientX, clientY)

    if (activeResizeHandle !== undefined && selectedLayerDetails) {
      setDragStart({ mouse: [x, y], mouseOriginal: [clientX, clientY], anchor: dotsFromLayerDetails(selectedLayerDetails)[activeResizeHandle] })
      return
    }

    if (mouseMode === MouseMode.InsertText) {
      createLayer(canvasId, {
        kind: 'text',
        color: '#000000',
        filter: [],
        fontFamily: 'Arial',
        fontSize: 50,
        offset: mousePos || [0, 0],
        text: '',
        visibility: true,
      })
      setMouseMode(MouseMode.Default)
      return
    }

    if (mouseMode === MouseMode.PlaceImage) {
      appContext.fileOpenElement.current?.form?.reset()
      appContext.fileOpenElement.current?.click()
      setMouseMode(MouseMode.Default)
      return
    }

    if (mouseMode === MouseMode.DragCanvas) {
      setDragStart({ mouse: [x, y], mouseOriginal: [clientX, clientY], anchor: transform.translate })
      return
    }

    if (!offscreen) { return }


    let hit = false
    for (const layer of layers.slice().reverse()) {
      const box = applyLayerToContext(layer, offscreen)
      if (coordinatesInBoundingBox(x, y, box)) {
        setSelectedLayer(layer.id)
        setDragStart({ mouse: [x, y], mouseOriginal: [clientX, clientY], anchor: [box.left, box.top] })
        hit = true
        break
      }
    }
    if (!hit) { setSelectedLayer(null) }
  }

  /**
   * mousemove event handler
   *
   * @param event
   * @returns
   */
  const mouseMove = (event: React.MouseEvent | React.TouchEvent) => {
    const { clientX, clientY } = 'touches' in event ? event.touches[0] : event
    const pos = adjustMouse(clientX, clientY)





    if (mouseMode === MouseMode.DragCanvas) {
      if (!dragStart) { return }
      if (keyPressed.LeftMouseButton) {
        setTransform(transform => ({
          ...transform, translate: [
            dragStart.anchor[0] + (dragStart.mouseOriginal[0] - clientX) / transform.scale,
            dragStart.anchor[1] + (dragStart.mouseOriginal[1] - clientY) / transform.scale,
          ],
        }))
      }
      return
    }


    setMousePos(pos)


    if (!selectedLayer || !dragStart) { return }



    const deltaX = pos[0] - dragStart.mouse[0]
    const deltaY = pos[1] - dragStart.mouse[1]



    if (activeResizeHandle !== undefined && selectedLayerDetails) {
      let [offset, dimensions]: [NumTuple, NumTuple] = [[0, 0], [0, 0]]
      if (activeResizeHandle === 0) {
        offset = [selectedLayerDetails.left + deltaX, selectedLayerDetails.top + deltaY]
        dimensions = [selectedLayerDetails.width - deltaX, selectedLayerDetails.height - deltaY]
      } else if (activeResizeHandle === 1) {
        offset = [selectedLayerDetails.left, selectedLayerDetails.top + deltaY]
        dimensions = [selectedLayerDetails.width + deltaX, selectedLayerDetails.height - deltaY]
      } else if (activeResizeHandle === 2) {
        offset = [selectedLayerDetails.left + deltaX, selectedLayerDetails.top]
        dimensions = [selectedLayerDetails.width - deltaX, selectedLayerDetails.height + deltaY]
      } else if (activeResizeHandle === 3) {
        offset = [selectedLayerDetails.left, selectedLayerDetails.top]
        dimensions = [selectedLayerDetails.width + deltaX, selectedLayerDetails.height + deltaY]
      }
      updateSelectedLayer(layer => !layer || layer.kind !== 'image' ? layer : ({ ...layer, offset, dimensions }))
      return
    }

    updateSelectedLayer(layer => !layer || !dragStart ? null : ({
      ...layer,
      offset: [dragStart.anchor[0] + deltaX, dragStart.anchor[1] + deltaY],
    }))
  }

  /**
   * mouseup event handler
   */
  const mouseUp = () => {
    setKeyPressed(cur => ({ ...cur, LeftMouseButton: false }))
    setDragStart(undefined)
    setSelectedLayerDetails(undefined)
    setActiveResizeHandle(undefined)
  }

  const onWheel = (ev: React.WheelEvent) => {
    if (keyPressed.Control) {
      setTransform({ ...transform, scale: Math.max(0.01, transform.scale - ev.deltaY * .005) })
    } else {
      const [deltaX, deltaY] = !keyPressed.Shift ? [ev.deltaX, ev.deltaY] : [ev.deltaY, ev.deltaX]
      setTransform({ ...transform, translate: [transform.translate[0] + deltaX, transform.translate[1] + deltaY] })
    }
  }

  /**
   * re-render viewport when state changes
   */
  useEffect(() => {
    render()
  }, [layers, selectedLayer, viewport, transform, size, canvas, availableFonts, activeResizeHandle])


  /**
   * load fonts
   */
  useEffect(() => {
    layers.filter((layer) => layer.kind === 'text').forEach(layer => {
      if (layer.kind === 'text' && !availableFonts.includes(layer.fontFamily)) {
        requestFont(layer.fontFamily)
      }
    })
  }, [layers, requestFont])

  return (
    <>
      <a ref={downloadLink} style={{ display: 'none' }} />
      <div
        ref={virtualCanvas}

        onMouseDown={mouseDown}
        onTouchStart={mouseDown}

        onMouseMove={mouseMove}
        onTouchMove={mouseMove}

        onMouseUp={mouseUp}
        onTouchEnd={mouseUp}

        onWheel={onWheel}
        onMouseLeave={() => setMousePos(undefined)}
        style={{ cursor: mouseModeToCursor(mouseMode) }}
        css={{
          position: 'relative',
          height: '100%',
          width: '100%',
          zIndex: 5,
        }}
      />
      {mousePos && (
        <div css={`
          pointer-events: none;
          height:50px;
          line-height:50px;
          padding:0 20px;
          position:absolute;
          bottom:10px;
          left:calc(50% - 100px);
          transform: translateX(-50%);
          background: #131313;
          border-radius: 5px;
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
        `}>
          <span style={{ marginRight: 25 }}>X {Math.round(mousePos[0])}</span>
          <span>Y {Math.round(mousePos[1])}</span>
        </div>
      )}
    </>
  )
}

const mouseModeToCursor = (mode: MouseMode) => {
  switch (mode) {
    case MouseMode.DragCanvas:
      return 'move'
    case MouseMode.InsertText:
      return 'text'
    case MouseMode.PlaceImage:
      return 'crosshair'
  }
  return 'default'
}
